import React, { useEffect,useState } from "react";
import NewsItem from "./NewsItem";
import Spinner from "./Spinner";
import propTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";

const News=(props)=> {
  
  const [articles,setArticles]=useState([])
  const [loading,setLoading]=useState(false)
  const [page,setPage]=useState(1)
  const [totalResults,setTotalResults]=useState(0)
  

  
  const  updateNews=async()=> {
    props.setProgress(10);
    const url = `https://gnews.io/api/v4/top-headlines?token=6e271bd624541e2d1f04fa34667ee8fc&lang=${props.country}&country=${props.country}&topic=${props.category}`;
    setLoading(true);
    // apiKey=4a1e521c3f244908bd83f3824251502c
    let data = await fetch(url);
    props.setProgress(30);
    let parsedData = await data.json();
    props.setProgress(70);
    setArticles(parsedData.articles);
    setTotalResults(parsedData.totalResults);
    setLoading(false);
    props.setProgress(100)
  }
  useEffect(()=>{
    document.title=`${(props.category).charAt(0).toUpperCase()+(props.category).slice(1)} - Stiri la Zi`;
    updateNews();
  },[])
  
  const fetchMoreData = async() => {
    
    const url = `https://gnews.io/api/v4/top-headlines?token=6e271bd624541e2d1f04fa34667ee8fc&lang=${props.country}&country=${props.country}&topic=${props.category}`;
    setPage(page+1)
    setLoading(true)
    let data = await fetch(url);
    let parsedData = await data.json();
    setArticles(articles.concat(parsedData.articles))
    setTotalResults(parsedData.totalResults)
    setLoading(false)
  };
    return (
<div className="container my-3">
<h1 style={{ marginTop: '90px' }} className="text-center bg-info">Stiri la Zi - Top {(props.category).charAt(0).toUpperCase() + (props.category).slice(1)} - Titluri</h1>
        {(!loading) ? (
        <>
        <InfiniteScroll
            dataLength={articles.length}
            next={fetchMoreData}
            hasMore={articles.length !== totalResults}
            loader={(loading) && <Spinner />} >
              <div className="container">
              <div className="row">

                {articles.map((element) => {
                  return (
                    <div key={element.url} className="col-md-4">
                      <NewsItem
                        author={element.source.name}
                        date={element.publishedAt}
                        title={element.title ? element.title : ""}
                        description={element.description ? element.description : ""}
                        imgUrl={element.image}
                        newsUrl={element.url} />
                    </div>

                  );
                })}
                             </div>
            </div>
        </InfiniteScroll></>
        
                )
                : <Spinner />
                }
 
      </div>
    );
  
}
News.defaultProps = {
  country: "ro",

  category: "general",
};
News.propTypes = {
  country: propTypes.string,
  pageSize: propTypes.number,
  category: propTypes.string,
};

export default News;
