import React from "react"
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="navbar fixed-bottom navbar-expand-lg navbar-dark bg-primary">
      <div className="nav-item"><Link to="/">Acasa</Link> Copyright &copy; 2022 Look4u.ro</div>
      
    </footer>
  )
}

export default Footer
